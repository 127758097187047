import React, { useState, useEffect } from 'react'
import * as t from 'counterpart'

export default React.forwardRef(function (props = {}, ref) {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    props = {
        ...props,
        className: 'iframe-block ' + (props.className || ''),
    };

    const targetDate = new Date(props.date).getTime();
    const title = props.title;
    
    delete props.title;
    delete props.date;
    
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const difference = targetDate - now;

            if (difference > 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((difference % (1000 * 60)) / 1000)
                });
            } else {
                clearInterval(timer);
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    return (
        <div {...props} ref={ref}>
            <div><strong style={{textAlign: "center"}}>{title}</strong></div>
            <span style={{padding: 10}}>{timeLeft.days} {t("Days")}</span>
            <span style={{padding: 10}}>{timeLeft.hours} {t("Hours")}</span>
            <span style={{padding: 10}}>{timeLeft.minutes} {t("Minutes")}</span>
            <span style={{padding: 10}}>{timeLeft.seconds} {t("Seconds")}</span>
        </div>
    );
});