import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import t from 'counterpart'

type PointsInputProps = {
    pointsAvailable: { cash: number; total_points: number }
    points_cash: number
    onChange: (value: number) => void
    disabled?: boolean
}

export const PointsInput = ({
    pointsAvailable,
    points_cash,
    onChange,
    disabled = false,
}: PointsInputProps) => {
    const [errors, setError] = useState('')

    const handlePointsChange = (value: number) => {
        setError('')

        if (value < 0) return
        if (value > (pointsAvailable.cash || 0))
            setError(`${t('max_points_allowed')} ${pointsAvailable.cash || 0}`)

        onChange && onChange(value)
    }

    return (
        <div className="loyality-points-input-wrapper" 
        style={{ width: '100%' }}
        >
                <h3>
                    <span>$</span> {t('use_your_points')}
                </h3>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder={`${pointsAvailable.total_points || 0} ${t('points')} = ${pointsAvailable.cash || 0} ${window.portalSetting('commerce.currency', '', false)} ${t('available')}`}
                    aria-label={t('use_points')}
                    aria-describedby="basic-addon2"
                    value={points_cash || ''}
                    className={t('dir')}
                    isInvalid={!!errors}
                    type="number"
                    disabled={disabled}
                    onChange={(e) =>
                        handlePointsChange((e.target as HTMLInputElement).valueAsNumber)
                    }
                />
                <Form.Control.Feedback type="invalid" style={{ fontSize: '12px' }}>
                    {errors}
                </Form.Control.Feedback>
            </InputGroup>
        </div>
    )
}
