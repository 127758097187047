import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ListGroup, Spinner, Button, Alert } from 'react-bootstrap'
import t from 'counterpart'
import api from '../../utilities/API'
import { paymentRedirection } from './actions'
import './style/Shipping.scss'

function Shipping() {
    const { store, id } = useParams()
    const navigate = useNavigate()
    const [method, setMethod] = useState(null)
    const [selectedRate, setSelectedRate] = useState(null)
    const [feedback, setFeedback] = useState({})

    useEffect(() => {
        api('/commerce/orders/' + id + '/shipping', {
            result: (data) => {
                ;(data.rates || []).sort((a, b) => (a.total > b.total ? 1 : -1))
                setMethod(data.rates)
                setFeedback({})
            },
            error: (error) => setFeedback({ error }),
        })
    }, [id])

    //
    const setOrderShipping = () => {
        setFeedback({ loading: true })

        api('commerce/orders/' + id + '/checkout', {
            method: 'PUT',
            data: { rate_id: selectedRate?.id },
            result: (res) => {
                setFeedback({})
                paymentRedirection(res, navigate, store)
            },
            error: (error) => setFeedback({ error }),
        })
    }
    return (
        <div id="cart-shipping-page" className="container page-body">
            <h1>
                <i className="fa fa-shopping-cart" />
                {t('delivery_method')}
            </h1>

            {feedback.error && <Alert variant="danger">{feedback.error}</Alert>}
            {!method && !feedback.error && <Spinner animation="border" size="xl" />}
            {method && (
                <div className="list-group-container">
                    <ListGroup>
                        {(method || []).map((rate) => (
                            <ListGroup.Item
                                className="method"
                                action
                                key={method.id + '-' + rate.courier_type}
                                onClick={() => setSelectedRate(rate)}
                            >
                                <div className="title">
                                    <input type="radio" checked={rate.id === selectedRate?.id} />
                                    <div>
                                        <img
                                            src={
                                                rate.courier_code == 'NAQEL'
                                                    ? '/assets/shipping/NAQEL.png'
                                                    : '/assets/shipping/' +
                                                      rate.courier_code +
                                                      '.svg'
                                            }
                                            alt=""
                                            width="20px"
                                            height="20px"
                                            style={{ width: '20px', height: '20px' }}
                                        />
                                        {rate.courier_name}
                                    </div>
                                </div>
                                <div className="cost">
                                    {rate.total} {window.portalSetting('commerce.currency')}
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>

                    <Button
                        id="continue"
                        disabled={!selectedRate || feedback.loading || feedback.error}
                        onClick={setOrderShipping}
                        load
                    >
                        <i className="fa fa-money-bill" /> {t('confirm_and_continue')}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Shipping
