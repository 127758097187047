import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import t from 'counterpart'
import { PaymentModals } from './types'
import api from '../../../utilities/API'
import './style/SummaryModal.scss'

type PaymentMethodsModalProps = {
    PaymentModalType: PaymentModals | ''
    setPaymentModalType: Function
    order: any
    handleConfirmPay: Function
}
const PaymentMethodsModal = ({
    PaymentModalType,
    setPaymentModalType,
    order,
    handleConfirmPay
}: PaymentMethodsModalProps) => {
    const [methods, setMethods] = useState([])
    const [selected, setSelected] = useState('')

    useEffect(() => {
        api('payment/methods/?active=1', {
            result: (data: any) => {
                setMethods(
                    (data || []).filter((d:any) => d.driver !== 'onsite').map((method: any) => {
                        if (method.frontend_setting) {
                            try {
                                method.frontend_setting = eval('(' + method.frontend_setting + ')')
                            } catch (e) {
                                console.log('payment method hook: ', e)
                            }
                        }

                        return method
                    })
                )

                // set the first method by default
                // if (data.length) {
                //     onChange(data[0])
                // }
            },
        })
    }, [])

    const onChange = (method: any) => {
        setSelected(method ? method.id : 'payment-cash')
    }

    return (
        <div className="summary-modal ">
            <Modal
                show={PaymentModalType === PaymentModals.PAYMENT_METHODS}
                dialogClassName="modal-90w m-auto payment-methods-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <div className="modal__header">
                        <div className="modal__header--title">
                            <span className="mb-1">{t('paymentMethods')}</span>
                            <small className="text-muted text-small">{t('selectPaymentMethod')}</small>
                        </div>
                        <button
                            className="btn btn-close"
                            onClick={() => setPaymentModalType('')}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-style-none m-0 p-0">
                        {methods.map((method: any) => (
                            <label
                                key={method.id}
                                className={'method ' + (method.id === selected ? 'selected' : '')}
                                id={'payment-method-' + method.id}
                            >
                                <Form.Check
                                    id={'payment-' + method.id}
                                    type="radio"
                                    label={method.displayed_name}
                                    checked={method.id === selected}
                                    onChange={() => onChange(method)}
                                />
                                <img src={method.image} alt="" />

                                <div className="payment-hook" />
                            </label>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex gap-2 justify-content-between align-items-center w-100">
                        <Button
                            className="w-50"
                            variant="outline-secondary"
                            onClick={() => setPaymentModalType('')}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            className="w-50"
                            variant="primary"
                            disabled={!selected}
                            onClick={() => handleConfirmPay(selected)}
                        >
                            {t('confirm')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PaymentMethodsModal
