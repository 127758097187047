import { Badge } from 'react-bootstrap'
import t from 'counterpart'
import { useParams } from 'react-router-dom'

function ProductBadges({ product, externalStyles = false }) {
    const { store } = useParams()

    const displayCashback = !!!window.portalSetting('commerce.cashback.display')
    const stores = window.portalSettingList['stores']
    // const toggleTheme = window.portalSetting('commerce.toggle_theme.enabled')
    const themesSettings = window.themesSettings(
        stores?.filter((s) => s.store_code === store.split('-')[0])[0].theme_key || ''
    ) // fixme: should be dyn
    const discountBadgeType = themesSettings?.blocks?.savingLabel
    // const bothTypeDiscount = themesSettings?.blocks?.savingLabel === 'both'
    const currency = window.portalSetting('commerce.currency')
    
    const renderDiscountBadge = () => {

        if (discountBadgeType) {
            return (
                <Badge bg="danger">
                    {discountBadgeType.includes("saved") ?  t('saved_amount') : t('Discount') }{' '}
                    {(discountBadgeType.includes("percent")) && (
                        <span className="percent">{product.saved_percent} ٪</span>
                    )}{' '}
                    {(discountBadgeType.includes("value")) && (
                        <span className="">
                            {Number((product.saved_amount).toFixed(2))} {currency}
                        </span>
                    )}
                </Badge>
            )
        }
        return <></>
    }
    
    const cashbackBadge = () => {
        const msg = t('With %(amount)s {currency} cashback', {
            amount: product.cash_back
        }).split('{currency}');

        msg.splice(1, 0, currency)
        
        return <Badge bg="warning">{msg}{' '}</Badge>
    }

    return (
        <div
            className="badges"
            style={
                externalStyles
                    ? {}
                    : { position: 'absolute', display: 'flex', flexWrap: 'wrap', top: 0, right: 0 }
            }
        >
            {product.labels?.map((label, i) => (
                <Badge key={i} bg="success">
                    {label}{' '}
                </Badge>
            ))}
            {!!product.cash_back && displayCashback &&  cashbackBadge()}
            {/* here */}
            {!!product.saved_amount && renderDiscountBadge()}
            {product.available_stock < 6 && (
                <Badge bg="info">
                    {t('Available')} {product.available_stock}{' '}
                </Badge>
            )}
            {!!product.gifts_ids && <Badge bg="info">{t('Gift')} </Badge>}
        </div>
    )
}

export default ProductBadges
