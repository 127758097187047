import React, { useEffect, useState } from 'react'
import '../style/TableOrderDetails.scss'
import t from 'counterpart'
import { useNavigate, useParams } from 'react-router-dom'
import API from 'utilities/API'
import GetOrderReceipt from './GetOrderReceipt'

const TableOrderDetails = () => {
    const navigate = useNavigate()
    const { store, id } = useParams()
    const [order, setOrder] = useState(null)
    const [ActiveTransactionInfo, setActiveTransactionInfo] = useState(false)
    const lang = window.location.pathname.split('/')[1].split('-')[1]

    const load = () => {
        API('/commerce/orders/' + id, {
            result: (order) => {
                setOrder(order)
            },
        })
    }

    useEffect(() => {
        load()
    }, [id])

    const getLastPayment = () => {
        return order?.payment ? order?.payment[order?.payment?.length - 1] : undefined
    }

    const calcRestOfPayment = () => {
        const total = order?.total
        let paid = 0
        order?.payment?.map((p) => {
            // if (p.status === 'success') {
            paid += p.amount
            // }
        })

        return total - paid < 0 ? 0 : total - paid
    }

    return (
        <div className="table__details">
            <div className="d-flex justify-content-start w-100 cursor-pointer">
                <i
                    onClick={() => {
                        navigate(`/${store}/tables/payment/${order.table_id}`)
                    }}
                    className={`fa-solid ${lang === 'en' ? 'fa-arrow-left' : 'fa-arrow-right'}`}
                ></i>
            </div>
            <h6 className="mt-2 mx-auto">{t('Your payment was successful!')}</h6>

            <div className="table__card">
                <span className="table__card--number">
                    {t('Table')} {order?.table_id}
                </span>
                <span className="table__card--type">{t('Partially paid')}</span>
            </div>

            <div className={`trnsaction__info ${ActiveTransactionInfo ? 'active' : ''}`}>
                <div
                    className="trnsaction__info__label"
                    onClick={() => setActiveTransactionInfo(!ActiveTransactionInfo)}
                >
                    <h6 className="mb-2 fw-bold">
                        {t('You paid')} {getLastPayment()?.amount} {t(getLastPayment()?.currency)}{' '}
                        {t('for your table')}
                    </h6>
                    <i
                        className={`fa-solid ${ActiveTransactionInfo ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                    ></i>
                </div>
                <div className="trnsaction__info__content">
                    <span className="content__label"> {t('Transaction info')} </span>

                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <small className="fw-bold"> {t('Transaction date')}</small>
                        <small>{getLastPayment()?.at}</small>
                    </div>
                    <p className="left_to_pay">
                        {calcRestOfPayment()} {t(getLastPayment()?.currency)} {t('is left to pay')}
                    </p>
                </div>
            </div>

            <div className="table__feadback">
                <GetOrderReceipt />
                <h6>{t('How was your dining experiance')}</h6>
                <a className="google__location" href="#">
                    <img
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt="google_logo"
                    />
                    <small>{t('Review this place on google')}</small>
                </a>
            </div>
        </div>
    )
}

export default TableOrderDetails
