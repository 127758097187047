const i18n = {
    ar: {
        dir: 'rtl',
        Neighborhood: 'الحي',
        'Postal Code': 'الرمز البريدي',
        'Plot Number': 'رقم قطعة الأرض',
        'Registry Name': 'إسم السجل',
        registry_name: 'إسم السجل',
        'Registry Number': 'رقم السجل',
        'registry_number': 'رقم السجل',
        'Tax Number': 'الرقم الضريبى',
        'tax_number': 'الرقم الضريبى',
        left: 'right',
        right: 'left',
        "Don't have an account? get one in few minutes":
            'لا تملك حساب؟ يمكنك إنشاء حساب جديد في دقائق',
        'Get access to your orders, favorites and recommendations':
            'لتصل إلي طلباتك وقائمتك المفضلة وتوصياتك للمنتجات التي تهتم بها',
        'First Name': 'الإسم الأول',
        'Last Name': 'الإسم الأخير',
        Password: 'كلمة المرور',
        Email: 'البريد الإلكتروني',
        Mobile: 'الهاتف الجوال',
        Register: 'تسجيل حساب جديد',
        'Do you have an account?': 'هل أنت عضو بالفعل؟',
        Login: 'تسجيل الدخول',
        'Did you forget your password? Type your email to change it':
            'هل نسيت كلمة المرور؟ قم بطلب تغييرها',
        'Change Password': 'تغيير كلمة المرور',
        "Didn't forget the password?": 'لا تحتاج تغيير كلمة المرور؟',
        'A confirmation code has been sent to your email':
            ' تم إرسال كود التأكيد إلي بريدك الإلكتروني',
        'Invalid confirmation code': 'كود التفعيل منتهي او غير صحيح',
        'Confirmation Code': 'كود التأكيد',
        'New Password': 'كلمة المرور الجديدة',
        'Password Confirmation': 'إعادة كلمة المرور',
        'Your account have been successfully created': 'تم تسجيل العضوية بنجاح',
        'Last step, Confirm your account by typing the code you received':
            'تبقي خطوة واحده، تفعيل الحساب عن طريق الكود المرسل',
        Confirm: 'تأكيد',
        'There are no data right now': 'لا يوجد بيانات لعرضها حالياً',
        More: 'المزيد',
        By: 'بواسطة',
        Delete: 'حذف',
        "Don't have account?": 'لا تملك حساب؟',
        'Register Now': 'سجل الأن',
        'Forget Password': 'نسيت كلمة المرور',
        Back: 'العودة',
        'Email / Username': 'إسم المستخدم / البريد الإلكتروني',
        'My Account': 'حسابي',
        'Resend Code': 'إعادة إرسال الكود',
        users: 'العضويات',
        profile: 'الملف الشخصي',
        setting: 'الإعدادات',
        Notifications: 'الإشعارات',
        Warning: 'تنبيه',
        confirm: 'تأكيد',
        All: 'الكل',
        'Please fill out the required fields': 'برجاء تعبئة الحقول المطلوبة',
        'Something went wrong, Please try again': 'حدث خطأ، برجاء المحاولة مرة أخري',
        Cancel: 'إلغاء',
        'Are you sure?': 'هل أنت متأكد؟',
        username: 'إسم المستخدم',
        password: 'كلمة المرور',
        Expires: 'ينتهي',
        'Manage your logged-in devices': 'إدارة جلسات الدخول',
        'Account Setting': 'إعدادات الحساب',
        Days: 'أيام',
        Hours: 'ساعات',
        Minutes: 'دقائق',
        Seconds: 'ثواني',
        full_name: 'الإسم الكامل',
        first_name: 'الإسم الأول',
        last_name: 'الإسم الأخير',
        current_password: 'كلمة المرور الحالية',
        mobile: 'الجوال',
        role_ids: 'الصلاحيات',
        email_activated_at: 'تاريخ تفعيل الحساب',
        'General Setting': 'الإعدادات العامة',
        'Welcome Back': 'مرحبًا بعودتك',
        'Use Mobile Number': 'إستخدم رقم الجوال',
        'Use Email': 'إستخدم البريد الإلكتروني',
        phone: 'الجوال',
        'Validation error': 'يجب تعبئة جميع الحقول ببيانات سليمة',
        day: 'يوم',
        Copy: 'نسخ',
        registeration_id: 'رقم التسجيل',
        tax_id: 'الرقم الضريبي',
        fixed_price: 'سعر ثابت',
        tax_percent: 'نسبة الضريبة',
        '1activeـproducts': 'نشطه',
        '2inactiveـproducts': 'مسودة',
        '3totalـproducts': 'المنتجات',
        '4availableـproducts': 'متوفر',
        '5unavailableـproducts': 'نفذت الكميه',
        'There are no requests on this table': 'لا توجد طلبات علي هذه الطاوله',
        'Would you like to add a tip ?': 'هل ترغب بإضافة إكراميه ؟',
        'custom tip': 'إكرامية مخصصه',
        tips: 'الإكراميات',
        'all tip amount are in': 'جميع الإكراميات بال',
        'Your payment was successful!': 'تمت عملية الدفع بنجاح!',
        'Partially paid': 'تم الدفع جزئيًا',
        'You paid': 'لقد دفعت',
        'for your table': 'لطاولتك',
        'Transaction info': 'معلومات المعاملة',
        'Transaction date': 'تاريخ المعاملة',
        'is left to pay': 'متبقي للدفع',
        'Get your receipt by email': 'احصل على إيصالك عبر البريد الإلكتروني',
        'How was your dining experiance': 'كيف كانت تجربتك في تناول الطعام',
        'Review this place on google': 'قم بمراجعة هذا المكان على جوجل',
        '1active_products': 'نشط',
        '2inactive_products': 'مسودة',
        '3total_products': 'المنتجات',
        '4available_products': 'متوفر',
        '5unavailable_products': 'غير متوفر',
        Subject: 'الموضوع',
        Text: 'النص',
        Short: 'قصير',
        Finance: 'التمويل',
        Branches: 'الفروع',
        Transactions: 'المعاملات',
        personalInfoUpdated: 'تم تحديث المعلومات الشخصية بنجاح',
        rating: 'التقييم',
        add_new_group: 'إضافة مجموعة جديدة',
        enter_group_name: 'أدخل اسم المجموعة',
        select_categories: 'اختر الفئات',
        select_printers: 'اختر الطابعات',
        create: 'إنشاء',
        add_printer: 'إضافة طابعة',
        enter_name: 'أدخل الاسم',
        ip: 'عنوان IP',
        enter_ip: 'أدخل عنوان IP',
        port: 'المنفذ',
        enter_port: 'أدخل المنفذ',
        add: 'إضافة',
        name_is_required: 'الاسم مطلوب',
        ip_is_required: 'عنوان IP مطلوب',
        port_is_required: 'المنفذ مطلوب',
        group_name_is_required: 'اسم المجموعة مطلوب',
        categories_is_required: 'التصنيفات مطلوبة',
        printers_is_required: 'الطابعات مطلوبة',
        cash: 'نقدًا',
        visa: 'فيزا',
        tamara: 'تمارا',
        tabby: 'تابي',
        set_id: 'نوع المنتج',
        google: 'جوجل',
        maps: 'الخرائط',
        analytics: 'الإحصائيات',
        seo: 'التحسين لمحركات البحث',
        'Get Receipt by Email': 'احصل على الإيصال عبر البريد الإلكتروني',
        'Enter your email': 'أدخل بريدك الإلكتروني',
        'Please enter a valid email address': 'يرجى إدخال عنوان بريد إلكتروني صالح.',
        'Receipt sent successfully!': 'تم إرسال الإيصال بنجاح!',
        Sending: 'جارٍ الإرسال',
        'Send Receipt': 'إرسال الإيصال',
        'Failed to send email': 'فشل في إرسال البريد الإلكتروني',
        subject: 'الموضوع',
        text: 'النص',
        short: 'قصير',
        deleted_items: 'العناصر المحذوفه',
        'Permanently Delete My Account': 'حذف حسابي بشكل نهائي',
        'By clicking the button below, you will permanently delete your account and all associated data, This action cannot be undone':
            'بالنقر على الزر أدناه، ستقوم بحذف حسابك بشكل نهائي وجميع البيانات المرتبطة به. لا يمكن التراجع عن هذا الإجراء.',
        users_ids: 'الأعضاء',
        account_deletion_confirmation: `<h1>تأكيد حذف الحساب</h1>
            <p>أنت على وشك حذف حسابك وجميع البيانات المرتبطة به هذا الإجراء <strong>دائم</strong> ولا يمكن التراجع عنه</p>
            <p>بمجرد حذف حسابك:</p>
            <ul>
                <li>سيتم حذف معلوماتك الشخصية، بما في ذلك اسمك وبريدك الإلكتروني والعناوين المحفوظة بشكل دائم</li>
                <li>لن تتمكن من الوصول إلى سجل الطلبات الخاص بك بعد الآن</li>
                <li>سيتم إلغاء أي طلبات جارية أو معلقة</li>
                <li>سيتم إخفاء البيانات المطلوبة لأغراض قانونية أو تنظيمية والاحتفاظ بها حسب الضرورة</li>
            </ul>
            <p style="color: red">هل أنت متأكد أنك تريد المتابعة؟</p>`,
        subtotal_items: 'إجمالي الأصناف',
        total_items: 'الأصناف',
        total_after_VAT: 'الإجمالي بعد الضريبة',
    },
    en: {
        dir: 'ltr',
        Neighborhood: 'Neighborhood',
        'Postal Code': 'Postal Code',
        'Plot Number': 'Plot Number',
        'Registry Name': 'Registry Name',
        registry_name: 'Registry Name',
        'Registry Number': 'Registry Number',
        registry_number: 'Registry Number',
        'Tax Number': 'Tax Number',
        'tax_number': 'Tax Number',
        left: 'left',
        right: 'right',
        "Don't have an account? get one in few minutes":
            "Don't have an account? Get one in a few minutes",
        'Get access to your orders, favorites and recommendations':
            'Get access to your orders, favorites, and recommendations',
        'First Name': 'First Name',
        'Last Name': 'Last Name',
        Password: 'Password',
        Email: 'Email',
        Mobile: 'Mobile',
        Register: 'Register',
        'Do you have an account?': 'Do you have an account?',
        Login: 'Login',
        'Did you forget your password? Type your email to change it':
            'Forgot your password? Enter your email to change it',
        'Change Password': 'Change Password',
        "Didn't forget the password?": "Didn't forget the password?",
        'A confirmation code has been sent to your email':
            'A confirmation code has been sent to your email',
        'Invalid confirmation code': 'Invalid confirmation code',
        'Confirmation Code': 'Confirmation Code',
        'New Password': 'New Password',
        'Password Confirmation': 'Password Confirmation',
        'Your account have been successfully created': 'Your account has been successfully created',
        'Last step, Confirm your account by typing the code you received':
            'Last step, confirm your account by entering the code you received',
        Confirm: 'Confirm',
        'There are no data right now': 'There is no data right now',
        More: 'More',
        By: 'By',
        Delete: 'Delete',
        "Don't have account?": "Don't have an account?",
        'Register Now': 'Register Now',
        'Forget Password': 'Forgot Password',
        Back: 'Back',
        'Email / Username': 'Email / Username',
        'My Account': 'My Account',
        'Resend Code': 'Resend Code',
        users: 'Users',
        profile: 'Profile',
        setting: 'Settings',
        Notifications: 'Notifications',
        Warning: 'Warning',
        confirm: 'Confirm',
        All: 'All',
        'Please fill out the required fields': 'Please fill out the required fields',
        'Something went wrong, Please try again': 'Something went wrong. Please try again',
        Cancel: 'Cancel',
        'Are you sure?': 'Are you sure?',
        username: 'Username',
        password: 'Password',
        Expires: 'Expires',
        'Manage your logged-in devices': 'Manage your logged-in devices',
        'Account Setting': 'Account Settings',
        Days: 'Days',
        Hours: 'Hours',
        Minutes: 'Minutes',
        Seconds: 'Seconds',
        full_name: 'Full Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        mobile: 'Mobile',
        role_ids: 'Roles',
        email_activated_at: 'Email Activation Date',
        'General Setting': 'General Settings',
        'Welcome Back': 'Welcome Back',
        'Use Mobile Number': 'Use Mobile Number',
        'Use Email': 'Use Email',
        phone: 'Phone',
        'Validation error': 'All fields must be filled out correctly',
        day: 'Day',
        Copy: 'Copy',
        registeration_id: 'Registration ID',
        tax_id: 'Tax ID',
        fixed_price: 'Fixed Price',
        tax_percent: 'Tax Percentage',
        '1activeـproducts': 'Active',
        '2inactiveـproducts': 'Draft',
        '3totalـproducts': 'Products',
        '4availableـproducts': 'Available',
        '5unavailableـproducts': 'Out of Stock',
        'There are no requests on this table': 'There are no requests on this table',
        'Would you like to add a tip ?': 'Would you like to add a tip?',
        'custom tip': 'Custom Tip',
        tips: 'Tips',
        'all tip amount are in': 'All tip amounts are in',
        'Your payment was successful!': 'Your payment was successful!',
        'Partially paid': 'Partially Paid',
        'You paid': 'You paid',
        'for your table': 'for your table',
        'Transaction info': 'Transaction Info',
        'Transaction date': 'Transaction date',
        'is left to pay': 'is left to pay',
        'Get your receipt by email': 'Get your receipt by email',
        'How was your dining experience': 'How was your dining experience?',
        'Review this place on google': 'Review this place on Google',
        '1active_products': 'Active',
        '2inactive_products': 'Draft',
        '3total_products': 'Products',
        '4available_products': 'Available',
        '5unavailable_products': 'Out of Stock',
        Subject: 'Subject',
        Text: 'Text',
        Short: 'Short',
        Finance: 'Finance',
        Branches: 'Branches',
        Transactions: 'Transactions',
        personalInfoUpdated: 'Personal information has been successfully updated',
        rating: 'Rating',
        add_new_group: 'Add New Group',
        enter_group_name: 'Enter Group Name',
        select_categories: 'Select Categories',
        select_printers: 'Select Printers',
        create: 'Create',
        add_printer: 'Add Printer',
        enter_name: 'Enter Name',
        ip: 'IP',
        enter_ip: 'Enter IP',
        port: 'Port',
        enter_port: 'Enter Port',
        add: 'Add',
        name_is_required: 'Name is required',
        ip_is_required: 'IP is required',
        port_is_required: 'Port is required',
        group_name_is_required: 'Group name is required',
        categories_is_required: 'Categories are required',
        printers_is_required: 'Printers are required',
        cash: 'Cash',
        visa: 'Visa',
        tamara: 'Tamara',
        tabby: 'Tabby',
        set_id: 'product type',
        'Get Receipt by Email': 'Get Receipt by Email',
        'Enter your email': 'Enter your email',
        'Please enter a valid email address': 'Please enter a valid email address.',
        'Receipt sent successfully!': 'Receipt sent successfully!',
        Sending: 'Sending',
        'Send Receipt': 'Send Receipt',
        'Failed to send email': 'Failed to send email',
        subject: 'Subject',
        text: 'Text',
        short: 'Short',
        html: 'HTML',
        deleted_items: 'Deleted Items',
        users_ids: 'Users',
        account_deletion_confirmation: `<h1>Confirm Account Deletion</h1>
            <p>You are about to delete your account and all associated data. This action is <strong>permanent</strong> and cannot be undone.</p>
            <p>Once your account is deleted:</p>
            <ul>
                <li>Your personal information, including your name, email, and saved addresses, will be permanently removed.</li>
                <li>Your order history will no longer be accessible.</li>
                <li>Any ongoing or pending orders will be canceled.</li>
                <li>Data required for legal or compliance purposes will be anonymized and retained as necessary.</li>
            </ul>
            <p style="color: red">Are you sure you want to proceed?</p>`,
        subtotal_items: 'Subtotal items',
        total_items: 'Items',
        total_after_VAT: 'Total after VAT',
    },
}

export default i18n
