import { Link } from 'react-router-dom'
import PaymentHooks from '../../Commerce/PaymentHooks'
import t from 'counterpart'

import { CartTotalsProps } from './Types'

export const CartTotals = ({ totals, user, store }: CartTotalsProps) => {
    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    return (
        <div id="cart-summary">
            <PaymentHooks product={{ current_price: totals.total }} />

            <div id="total">
                {totals.totalSaved !== 0 && <div className="saved">{t('Total Saved')}</div>}
                {totals.totalSaved !== 0 && (
                    <div className="saved"> {totals.totalSaved.toFixed(2).toLocaleString()}</div>
                )}
                {totals.totalCashBack !== 0 && !displayCashback && (
                    <div className="saved">{t('cash_back')}</div>
                )}
                {totals.totalCashBack !== 0 && !displayCashback && (
                    <div className="saved"> {totals.totalCashBack.toFixed(2).toLocaleString()}</div>
                )}
                <div>{t('Cart Total')}</div>
                <div>{(totals.total - totals.totalCashBack).toFixed(2).toLocaleString()}</div>
            </div>
            <div className="actions">
                {user && (
                    <Link to={'/' + store + '/cart/order'}>
                        <i className="fa fa-money-bill" />
                        {t('Continue To Checkout')}
                    </Link>
                )}
                {!user && (
                    <Link to={`/${store}/auth/login?form=/${store}/cart`}>
                        <i className="fa fa-user-alt" />
                        {t('Continue To Checkout')}
                    </Link>
                )}
            </div>
        </div>
    )
}
