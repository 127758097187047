import api from './utilities/API'
import storage from './utilities/storage'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'

window.platform = Capacitor.getPlatform()

//
export function loadUser() {
    return function (dispatch) {
        if (!storage('access_token')) {
            dispatch({ type: 'PORTAL_SET_READY', payload: true })
            return
        }

        dispatch({ type: 'PORTAL_LOADING' })

        api('/auth/user', {
            result: (user) => dispatch({ type: 'PORTAL_SET_USER', payload: user }),

            error: (error, details) => {
                if (details.response && details.response.status === 401) {
                    dispatch({ type: 'PORTAL_SET_READY', payload: true })
                } else {
                    dispatch({ type: 'PORTAL_ERROR', payload: error })
                }
            },
        })
    }
}

//
export function loadCategories() {
    return function (dispatch) {
        api('/commerce/categories/?active=1', {
            result: ({ data }) => {
                let categories = {}
                ;(data || []).forEach((cat) => {
                    if (!categories[cat.parent_id]) {
                        categories[cat.parent_id] = []
                    }

                    categories[cat.parent_id].push(cat)
                })

                storage('portal_categories', categories)
                dispatch({ type: 'PORTAL_SET_CATEGORIES', payload: categories })
            },
        })
    }
}

//
export function setReady(isReady) {
    return function (dispatch) {
        dispatch({ type: 'PORTAL_SET_READY', payload: isReady })
    }
}

//
export async function pushNotifications() {
    if (window.platform === 'web') {
        return
    }

   try {
       let permStatus = await PushNotifications.checkPermissions();

       if (permStatus.receive === 'prompt') {
           permStatus = await PushNotifications.requestPermissions();
       }

       if (permStatus.receive !== 'granted') {
           throw new Error('User denied permissions!');
       }

       // fixme: await PushNotifications.register();

       await PushNotifications.addListener('registration', token => {
           console.info('Push notifications Registration token: ', token.value);
       });

       await PushNotifications.addListener('registrationError', err => {
           console.error('Push notifications Registration error: ', err.error);
       });

       await PushNotifications.addListener('pushNotificationReceived', notification => {
           console.log('Push notification received: ', notification);
       });

       await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
           console.log('Push notification action performed', notification.actionId, notification.inputValue);
       });
   } catch (error) {
       console.error('Push notifications error: ', error);
   }
}
