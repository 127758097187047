import t from 'counterpart'
import moment from 'moment'

type ProductProps = {
    item: any
    cart: any
}

export const ProductItem = ({ item, cart }: ProductProps) => {
    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    const currentPrice =
        displayCashback && item.cash_back ? item.current_price - item.cash_back : item.current_price
    return (
        <div
            key={item.cart_id}
            className={'product ' + (cart.productError === item.id ? 'error' : '')}
        >
            <div className="content-wrapper">
                <div className="image-wrapper col-4">
                    <img src={item.thumbnail} alt="Product" />
                </div>
                <div className="product-info">
                    <div className="quantity-tag">X {item.cart_quantity}</div>
                    <div className="line-truncate name">{item.name}</div>{' '}
                    <div className={`${item.cart_options && 'price-options-styles'} mt-2`}>
                        <div className="price">
                            {item.current_price === 0
                                ? t('Free')
                                : [(currentPrice + (item.cart_options_price || 0)).toLocaleString() +
                                  ' ',
                                  window.portalSetting('commerce.currency')]}
                            <span className="old-price">
                                {item.saved_amount || (item.cash_back && displayCashback)
                                    ? [item.price.toLocaleString() +
                                      ' ',
                                      window.portalSetting('commerce.currency')]
                                    : ''}
                            </span>
                        </div>

                        {item.cart_options && (
                            <div className="">
                                ---------
                                <br />
                                {t('Price')}: {item.price}
                                {window.portalSetting('commerce.currency')}
                                {item.cart_options.map((option: any) => {
                                    return (
                                        <div key={option.id} className="option">
                                            <span>{option.key}: </span>
                                            <span>
                                                {(
                                                    option.price * item.cart_quantity
                                                ).toLocaleString()}
                                            </span>
                                            {window.portalSetting('commerce.currency')}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {item.cart_expiration && (
                <h5 style={{ color: 'red' }}>
                    {t('Expires')} {moment(item.cart_expiration).fromNow()}
                </h5>
            )}
        </div>
    )
}
