import api from '../../utilities/API'
import storage from '../../utilities/storage'
import {
    triggerAddToCartGEvent,
    triggerRemoveFromCartGEvent,
} from '../Commerce/analytics/g-tags-events-triggers'
import {Browser} from "@capacitor/browser";

//
export function loadCart(local = false, showOffCanvasCart = false) {
    return function (dispatch, state) {
        const setData = (data) => dispatch({ type: 'CART_SET_ITEMS', payload: data || [] })

        // local cart
        if (local) {
            setData(Object.values(storage('offline-cart') || {}))
            if (showOffCanvasCart) {
                dispatch(toggleOffCanvasCart(true))
            }
            return
        }

        if (state().cart.isLoading) {
            return
        }

        // online cart
        dispatch({ type: 'CART_LOADING' })

        api('commerce/cart/', {
            result: (data) => {
                setData(data)

                if (showOffCanvasCart) {
                    dispatch(toggleOffCanvasCart(true))
                }
            },
            error: (error) => dispatch({ type: 'CART_ERROR', payload: error }),
        })
    }
}

//
export function addToCart({ data, after, local = false, token = '', product = {} }) {
    const newData = [...data]

    return async function (dispatch) {
        triggerAddToCartGEvent(product, window.portalSetting('commerce.currency', '', false))
        // local cart1
        if (local) {
            let cart = storage('offline-cart') || {}
            let gifts = []
            for (const { product_id, quantity, productData, options } of newData) {
                const giftPromises = (productData.gifts_ids || []).map(async (gift_id) => {
                    try {
                        const data = await new Promise((resolve, reject) => {
                            api('/commerce/products/' + gift_id, {
                                result: (data) => resolve(data),
                                error: (err) => reject(err),
                            })
                        })
                        gifts.push(data)
                    } catch (e) {
                        console.log(e)
                    }
                })

                // Wait for all gift fetch promises to resolve
                if (giftPromises) {
                    await Promise.all(giftPromises)
                }

                // Update the cart
                cart[product_id] = {
                    ...productData,
                    product_id,
                    quantity,
                    options,
                    cart_id: product_id,
                    cart_quantity: quantity,
                    gifts: gifts,
                }
            }

            storage('offline-cart', cart)

            dispatch(loadCart(local, true))
            after('success')
            return
        }

        // online cart
        api('commerce/cart/', {
            method: 'POST',
            data: {
                carts: JSON.stringify(newData),
            },
            headers: {
                'X-Seats-Token': token,
            },
            result: () => {
                dispatch(loadCart(false, true))
                after('success')
            },
            error: (error) => {
                after('', error)
            },
        })
    }
}

//
export function submitLocalCart(after) {
    let data = Object.values(storage('offline-cart') || {}).map(({ product_id, quantity }) => ({
        product_id,
        quantity,
    }))

    return addToCart(data, () => {
        storage('offline-cart', null)
        after()
    })
}

//
export function changeQuantity(id, quantity, local = false) {
    return function (dispatch) {
        // local cart
        if (local) {
            let cart = storage('offline-cart') || {}
            cart[id].cart_quantity = quantity
            storage('offline-cart', cart)
            dispatch(loadCart(local))
            return
        }

        // online cart
        api('commerce/cart/' + id, {
            method: 'PUT',
            data: { carts: JSON.stringify([{ quantity: parseInt(quantity), options: [] }]) },
            result: () => dispatch(loadCart()),
            error: (error) => dispatch({ type: 'CART_ERROR', payload: error }),
        })
    }
}

//
export function deleteItem(product, local) {
    const id = product.cart_id
    triggerRemoveFromCartGEvent(product, window.portalSetting('commerce.currency', '', false))

    return function (dispatch) {
        // local cart
        if (local) {
            let cart = storage('offline-cart') || {}
            if (id) {
                delete cart[id]
            } else {
                cart = {}
            }

            storage('offline-cart', cart)
            dispatch(loadCart(local))
            return
        }

        // online cart
        api('commerce/cart/' + id, {
            method: 'DELETE',
            result: () => dispatch(loadCart()),
            error: (error) => dispatch({ type: 'CART_ERROR', payload: error }),
        })
    }
}

//
export function toggleOffCanvasCart(show) {
    return function (dispatch) {
        dispatch({ type: 'CART_TOGGLE_OffCanvas', payload: show })
    }
}

//
export function placeOrder(data, navigate, store) {
    return function (dispatch) {
        dispatch({ type: 'CART_LOADING' })

        api('commerce/orders/', {
            method: 'POST',
            data,
            result: (order) => {
                dispatch({ type: 'CART_ORDER_PLACED' })
                paymentRedirection(order, navigate, store)
            },
            error: (error, details) => {
                if (details.response) {
                    const data = details.response.data || {}

                    if (details.response.status === 422) {
                        error = { message: data.validation }
                    } else if (data.error_code === 'options_error') {
                        error = { message: error, productError: data }
                    } else if (typeof error === 'string') {
                        error = { message: error }
                    }
                }

                dispatch({ type: 'CART_ERROR', payload: error })
            },
        })
    }
}

//
export function paymentRedirection(order, navigate, store) {
    // redirect draft orders to shipping options
    if (order.status === 'draft') {
        navigate('/' + store + '/cart/shipping/' + order.id)
        return
    }

    // redirect to success page if cash
    if (!order.payment_method_id || order.payment_driver === 'onsite' || order.paid) {
        navigate(`/${store}/cart/confirm/${order.id}`)
        return
    }

    const redirectTo = order.payment.length ? order.payment[0]?.redirect : ""
    if (redirectTo) {
        if(window.platform === "web") {
            window.location.href = redirectTo
        } else {
            Browser.open({ url: redirectTo });
            window.location.href = "/";
        }
        return
    }
    navigate(`/${store}/orders/${order.id}`)
}
