import React, { useEffect, useState } from 'react'
import t from 'counterpart'
import { Card } from 'react-bootstrap'

const Tips = ({
    orderTipsValues,
    order,
    TotalOrder,
    TipAmount,
    setTipAmount,
    IsActiveCustomTipAmoun,
    setIsActiveCustomTipAmoun,
}: any) => {
    const [tipsOptions, setTipOtions] = useState<number[]>(orderTipsValues)

    const calcTipAmount = (percentage: number) => {
        return Number(((TotalOrder * percentage) / 100).toFixed(2))
    }

    const handleCustomTipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTipAmount(Number(e.target.value))
    }

    const handleSelectCustomTip = () => {
        setIsActiveCustomTipAmoun(true)
        setTipAmount(0)
    }

    const handleSelectTipOption = (t: number) => {
        setIsActiveCustomTipAmoun(false)
        setTipAmount(t)
    }

    useEffect(() => {
        setTipAmount(0)
        setIsActiveCustomTipAmoun(false)
        setTipOtions(orderTipsValues)
    }, [TotalOrder])

    return (
        <Card className="payment__total mt-2 p-3">
            <Card.Title className="">
                <div className="h6 mb-0 d-flex justify-content-between">
                    {t('Would you like to add a tip ?')}
                </div>
                {/* <small className="text-muted text-small">{t('youCanSplitTheBill')}</small> */}
            </Card.Title>

            <Card.Body className="w-full p-1">
                <div className="tips__container">
                    {tipsOptions.map((tip) => (
                        <div
                            key={tip}
                            onClick={() => handleSelectTipOption(tip)}
                            className={`tip__option ${TipAmount === tip && !IsActiveCustomTipAmoun && 'active'}`}
                        >
                            <span className="tip__option--percentage">{tip}%</span>
                            <span className="tip__option--value">
                                {calcTipAmount(tip)} {t(order?.currency)}
                            </span>
                        </div>
                    ))}
                    <div
                        className="tip__option custom__tip"
                        onClick={() => handleSelectCustomTip()}
                    >
                        <span className="tip__option--percentage">{t('custom tip')}</span>
                        <span className="tip__option--value"></span>
                        {IsActiveCustomTipAmoun && (
                            <input
                                placeholder={t('custom tip')}
                                onChange={handleCustomTipChange}
                                type="number"
                                className="form-control"
                            />
                        )}{' '}
                    </div>
                    <div className="p-0">
                        <small>
                            {t('all tip amount are in')} {t(order?.currency)}
                        </small>
                    </div>
                </div>{' '}
            </Card.Body>
        </Card>
    )
}

export default Tips
