import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ListGroup, Form, Badge, Button, Spinner, Modal } from 'react-bootstrap'
import { Navigate, useParams } from 'react-router-dom'
import { QrReader } from 'react-qr-reader'
import api from '../../utilities/API'
import './style/OrderConfirmation.scss'

let searchTimer
const IdleStatus = 0
const ValidStatus = 1
const InvalidStatus = 2
const LoadingStatus = 3
const labels = {
    [IdleStatus]: 'Touch to Scan',
    [ValidStatus]: 'Valid Code',
    [InvalidStatus]: 'Invalid Code',
    [LoadingStatus]: 'Loading ..',
}

function OrderConfirmation() {
    const { user, ready } = useSelector((state) => state.portal)
    const [scan, setScan] = useState(false)
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState(null)
    const [status, setStatus] = useState(IdleStatus)
    const [error, setError] = useState(null)
    const store = useParams().store

    //
    useEffect(() => loadProducts(), [])

    //
    const loadProducts = (search = '') => {
        api('/commerce/products/?agent_products=true&search=' + search, {
            result: (r) => setProducts(r.data || []),
        })
    }

    //
    const searchProducts = (search) => {
        if (searchTimer) {
            clearTimeout(searchTimer)
        }

        searchTimer = setTimeout(() => loadProducts(search), 500)
    }

    //
    const onScan = (code) => {
        if (status !== 0 || !code || !product) {
            setStatus(IdleStatus)
            return
        }

        setStatus(LoadingStatus)

        api('/commerce/confirmation', {
            method: 'POST',
            data: { code, type: 'order_item', product_id: product.id },
            result: (data) => {
                setStatus(ValidStatus)
                setScan(false)
            },
            error: (error, details) => {
                setStatus(InvalidStatus)
                setScan(false)

                if (details && details.response && details.response.status === 409) {
                    const data = details.response.data
                    setError('At: ' + data.confirmed_at + '\nBy:' + data.confirmed_by_name)
                }
            },
        })
    }

    const toggleScan = () => {
        setScan(!scan)
    }

    //
    if (ready && !user) {
        return <Navigate to={`/${store}/auth/login?from=/${store}/confirmation&full_screen=true`} />
    }

    //
    const productSelector = () => {
        return (
            <div id="product-selector">
                <Form.Control
                    placeholder="Search Products"
                    onChange={(e) => searchProducts(e.target.value)}
                />

                <ListGroup>
                    {products.map((p) => (
                        <ListGroup.Item key={p.id} action onClick={() => setProduct(p)}>
                            <Badge variant="primary">{p.id}</Badge> {p.name} <br />{' '}
                            <small>{p.sales_start}</small>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        )
    }

    //
    const scanner = () => {
        return (
            <>
                <Button
                    className="back"
                    onClick={() => setProduct(null)}
                    variant="secondary"
                    size="sm"
                >
                    Change Event
                </Button>

                <h4>{product.name}</h4>
                <h5>{product.sales_start}</h5>

                <h6 className={'status-' + status}>{labels[status]}</h6>
                <div
                    id="scanner"
                    className={'status-' + status}
                    onClick={toggleScan}
                    onTouchStart={toggleScan}
                >
                    {' '}
                    {/* onTouchEnd={endScan} onMouseUp={endScan} */}
                    {scan ? (
                        <QrReader
                            delay={300}
                            onScan={onScan}
                            onError={console.log}
                            style={{ width: '100%' }}
                        />
                    ) : (
                        <div className="placeholder">
                            <div>
                                <svg
                                    height="200px"
                                    viewBox="0 0 512 512"
                                    width="200px"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="m30 30h90v-30h-120v120h30zm0 0" />
                                    <path d="m392 0v30h90v90h30v-120zm0 0" />
                                    <path d="m482 482h-90v30h120v-120h-30zm0 0" />
                                    <path d="m30 392h-30v120h120v-30h-90zm0 0" />
                                    <path d="m61 60v150h150v-90h30v-30h-30v-30zm120 120h-90v-90h90zm0 0" />
                                    <path d="m451 450v-150h-60v-30h-30v30h-90v30h30v30h-30v30h30v60zm-120-120h90v90h-90zm0 0" />
                                    <path d="m151 270h60v-30h-150v30h60v30h-30v30h30v60h-30v30h30v30h150v-30h-30v-30h-30v30h-60v-30h30v-30h-30zm0 0" />
                                    <path d="m121 120h30v30h-30zm0 0" />
                                    <path d="m361 120h30v30h-30zm0 0" />
                                    <path d="m391 210h60v-150h-150v150h60v30h30zm-60-30v-90h90v90zm0 0" />
                                    <path d="m451 270v-30c-7.257812 0-52.691406 0-60 0v30zm0 0" />
                                    <path d="m361 360h30v30h-30zm0 0" />
                                    <path d="m241 330h30v30h-30zm0 0" />
                                    <path d="m181 360h30c0-7.257812 0-52.691406 0-60h-30zm0 0" />
                                    <path d="m211 270h30v30h-30zm0 0" />
                                    <path d="m91 330h-30v60h30c0-7.257812 0-52.691406 0-60zm0 0" />
                                    <path d="m61 420h30v30h-30zm0 0" />
                                    <path d="m241 60h30v30h-30zm0 0" />
                                    <path d="m241 180h30c0-7.257812 0-52.691406 0-60h-30zm0 0" />
                                    <path d="m271 240v-30h-30v60h120v-30zm0 0" />
                                </svg>
                            </div>
                        </div>
                    )}
                </div>

                {status === LoadingStatus && (
                    <Spinner animation="border" variant="primary">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )}
            </>
        )
    }

    return (
        <div id="commerce-confirmation">
            <div id="frame">
                <h1>Check-in</h1>
                {!product && productSelector()}
                {product && scanner()}
                <img className="logo" src={window.portalSetting('core.app_logo')} alt="" />
            </div>

            <Modal show={error !== null} onHide={() => setError(null)} centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <pre>{error}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setError(null)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default OrderConfirmation
